





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsPhotoGallery extends Vue {
    @Prop({ default: () => [] }) private photolist!: object[]; // cms图片集数据

    CarouselShow: boolean = false;
    showImg: boolean = false;
    showImgSrc: string = '';

    zoomImg (img) {
      if (typeof (img) === 'string') {
        this.showImgSrc = img;
      }

      this.showImg = !this.showImg;
    }

    get lang () {
      return this.$Storage.get('locale');
    }
}
